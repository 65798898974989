require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

require("./stylesheets/acesso.scss");
require("./stylesheets/style.css");
require("animate.css");

require("../images/logo-unimed.png");

require("./bootstrap/js/bootstrap.min.js");
require("jquery");